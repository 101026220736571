import React, { useState } from "react";
import {
  Box,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import exclamation from "./assets/exclamation.png";
import ray from "./assets/ray.png";
import sun from "./assets/sun.png";
import sticker from "./assets/sticker.png";
import ray1 from "./assets/ray-1.png";
import star from "./assets/star.png";
import oil from "./assets/oil.png";
import circle from "./assets/circle.png";
import poppy from "./assets/poppy.png";

const App = () => {
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    number: "",
    email: "",
    nationality: "",
    city: "",
  });

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(file);
      setOpen(true);
    }
  };

  const handleConfirm = async () => {
    const data = new FormData();
    data.append("name", formData.name);
    data.append("number", formData.number);
    data.append("email", formData.email);
    data.append("nationality", formData.nationality);
    data.append("city", formData.city);
    if (selectedImage) {
      data.append("image", selectedImage);
    }

    try {
      const response = await axios.post(
        "https://winwithsunny.symloop.com/api/records",
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      toast.success("Record created successfully!", {
        position: "bottom-center",
      });
      setFormData({
        name: "",
        email: "",
        number: "",
        nationality: "",
        city: "",
      });
      setOpen(false);
    } catch (error) {
      toast.error("Failed to create the record. Please try again.", {
        position: "bottom-center",
      });
    }
  };

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        margin: 0,
        padding: 0,
      }}
    >
      <ToastContainer />
      <Box
        sx={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          padding: 2,
          position: "relative",
        }}
      >
        <img
          src={oil}
          alt="Text"
          style={{
            position: "absolute",
            top: "2%",
            left: "38%",
            height: "95%",
          }}
        />
        <img
          src={circle}
          alt="Text"
          style={{
            position: "absolute",
            top: "20%",
            left: "7%",
            height: "60%",
          }}
        />

        <img
          src={exclamation}
          alt="Text"
          style={{
            position: "absolute",
            bottom: "2%",
            left: "37%",
            height: "15%",
          }}
        />
        <img
          src={ray}
          alt="Text"
          style={{
            position: "absolute",
            top: "30%",
            left: "57%",
            height: "5%",
            zIndex: -1,
          }}
        />
        <img
          src={ray}
          alt="Text"
          style={{
            position: "absolute",
            top: "15%",
            left: "80%",
            height: "5%",
            zIndex: -0,
          }}
        />
        <img
          src={sun}
          alt="Text"
          style={{
            position: "absolute",
            top: "10%",
            right: "9%",
            height: "15%",
          }}
        />
        <img
          src={ray1}
          alt="Text"
          style={{
            position: "absolute",
            top: "5%",
            right: "4%",
            height: "13%",
          }}
        />
        <img
          src={sticker}
          alt="Text"
          style={{
            position: "absolute",
            top: "12%",
            right: "5%",
            height: "9%",
          }}
        />
        <img
          src={star}
          alt="Text"
          style={{
            position: "absolute",
            top: "25%",
            left: "3%",
            height: "12%",
          }}
        />
        <img
          src={poppy}
          alt="Text"
          style={{
            position: "absolute",
            top: "5%",
            right: "20%",
            height: "30%",
            zIndex: -1,
          }}
        />

        <Box
          sx={{
            top: "25%",
            right: "4%",
            height: "70%",
            width: "33%",
            padding: 2,
            position: "absolute",
          }}
        >
          <TextField
            fullWidth
            placeholder="Name"
            name="name"
            variant="outlined"
            InputProps={{
              style: {
                textAlign: "center",
                color: "black", // Text color inside the input
                fontWeight: "bold",
              },
            }}
            value={formData.name}
            onChange={handleInputChange}
            sx={{
              mb: 2,
              borderRadius: "20px",
              backgroundColor: "rgba(255, 255, 255, 0.8)", // White with 80% opacity
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "transparent",
                },
                "&:hover fieldset": {
                  borderColor: "transparent",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "transparent",
                },
                "& input::placeholder": {
                  textAlign: "center",
                  color: "black", // Ensure placeholder text is black
                  fontWeight: "bold",
                },
              },
            }}
          />

          <TextField
            fullWidth
            placeholder="Number"
            name="number"
            variant="outlined"
            
            InputProps={{
              style: {
                textAlign: "center",
                color: "black", // Text color inside the input
                fontWeight: "bold",
              },
            }}
            value={formData.number}
            onChange={handleInputChange}
            sx={{
              mb: 2,
              borderRadius: "20px",
              backgroundColor: "rgba(255, 255, 255, 0.8)", // White with 80% opacity
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "transparent",
                },
                "&:hover fieldset": {
                  borderColor: "transparent",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "transparent",
                },
               "& input::placeholder": {
                  textAlign: "center",
                  color: "black", // Ensure placeholder text is black
                  fontWeight: "bold",
                },
              },
            }}
          />
          <TextField
            fullWidth
            placeholder="Email"
            name="email"
            variant="outlined"
            InputProps={{
              style: {
                textAlign: "center",
                color: "black", // Text color inside the input
                fontWeight: "bold",
              },
            }}
            value={formData.email}
            onChange={handleInputChange}
            sx={{
              mb: 2,
              borderRadius: "20px",
              backgroundColor: "rgba(255, 255, 255, 0.8)", // White with 80% opacity
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "transparent",
                },
                "&:hover fieldset": {
                  borderColor: "transparent",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "transparent",
                },
               "& input::placeholder": {
                  textAlign: "center",
                  color: "black", // Ensure placeholder text is black
                  fontWeight: "bold",
                },
              },
            }}
          />
          <TextField
            fullWidth
            placeholder="Nationality"
            name="nationality"
            variant="outlined"
            InputProps={{
              style: {
                textAlign: "center",
                color: "black", // Text color inside the input
                fontWeight: "bold",
              },
            }}
            value={formData.nationality}
            onChange={handleInputChange}
            sx={{
              mb: 2,
              borderRadius: "20px",
              backgroundColor: "rgba(255, 255, 255, 0.8)", // White with 80% opacity
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "transparent",
                },
                "&:hover fieldset": {
                  borderColor: "transparent",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "transparent",
                },
                "& input::placeholder": {
                  textAlign: "center",
                  color: "black", // Ensure placeholder text is black
                  fontWeight: "bold",
                },
              },
            }}
          />
          <TextField
            fullWidth
            placeholder="City"
            name="city"
            variant="outlined"
            InputProps={{
              style: {
                textAlign: "center",
                color: "black", // Text color inside the input
                fontWeight: "bold",
              },
            }}
            value={formData.city}
            onChange={handleInputChange}
            sx={{
              mb: 2,
              borderRadius: "20px",
              backgroundColor: "rgba(255, 255, 255, 0.8)", // White with 80% opacity
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "transparent",
                },
                "&:hover fieldset": {
                  borderColor: "transparent",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "transparent",
                },
                "& input::placeholder": {
                  textAlign: "center",
                  color: "black", // Ensure placeholder text is black
                  fontWeight: "bold",
                },
              },
            }}
          />

          <Button
            fullWidth
            variant="contained"
            component="label"
            sx={{
              borderRadius: "20px",
              backgroundColor: "#8b0f0c",
              fontWeight: "bold",
              color: "white",
              height: "56px",
              "&:hover": {
                backgroundColor: "#730c0a", // A slightly darker shade close to your background color
              },
            }}
          >
            UPLOAD RECEIPT
            <input
              type="file"
              hidden
              accept="image/*"
              onChange={handleImageUpload}
            />
          </Button>
        </Box>
      </Box>

      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Confirm Image Upload</DialogTitle>
        <DialogContent>
          {selectedImage && (
            <img
              src={URL.createObjectURL(selectedImage)}
              alt="Selected"
              style={{ width: "100%", height: "auto", borderRadius: "8px" }}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirm} color="primary" variant="contained">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default App;
